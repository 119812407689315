<template>
  <div>
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast :key="'toast' + index" :show="true" :header="toast.title">
          {{ toast.message }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Crear Usuario"
      color="primary"
      class="edit-user-modal"
      :show.sync="showModal"
      :close-on-backdrop="false"
    >
          <template #header>
        <div style="display: flex; justify-content: space-between; width: 100%;">
            <h4 class="mb-0">Crear Usuario</h4>
            <CButton color="primary" @click="closeModal">X</CButton>
        </div>
      </template>
      <CCard style="max-height: 70vh; overflow-y: scroll">
        <CCardBody>
          <CForm>
            <CRow>
              <CCol>
                <label>Nombre:</label>
                <CInput placeholder="Nombre" v-model="newUser.name">
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput>
                <label>Rut:</label>
                <CInput
                  placeholder="Rut"
                  v-model="newUser.rut"
                  @keyup="
                    (rut) => {
                      formatRut(newUser.rut);
                    }
                  "
                >
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput>
                <label>Email:</label>
                <CInput
                  placeholder="Email"
                  v-model="newUser.email"
                  @input="validateEmail"
                >
                  <template #prepend-content
                    ><CIcon name="cil-envelope-closed"
                  /></template>
                </CInput>
                <label>Teléfono</label>
                <CInput
                  placeholder="Teléfono"
                  v-model="newUser.telefono"
                  maxlength="9"
                >
                  <template #prepend-content
                    ><font-awesome-icon icon="phone" /> +56</template
                  >
                </CInput>
                <label>Dirección</label>
                <CInput placeholder="Dirección" v-model="newUser.direccion">
                  <template #prepend-content
                    ><font-awesome-icon icon="map-marked-alt"
                  /></template>
                </CInput>

                <label>Género</label>
                <v-select
                  :options="generos"
                  label="name"
                  :reduce="(data) => data.id"
                  v-model="newUser.genero"
                ></v-select>

                <label class="pt-2 mb-0">Fecha de Nacimiento: </label>
                <datetime
                  width="100%"
                  class="pl-1 my-2 datepickers__datepicker"
                  type="date"
                  v-model="newUser.nacimiento"
                  :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
                ></datetime>
              </CCol>

              <CCol>
                <label>Contraseña:</label>
                <CInput
                  type="password"
                  placeholder="Contraseña"
                  v-model="newUser.password"
                >
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput>
                <label>Seleccione País:</label>
                <v-select
                  style="margin-bottom: 1rem"
                  :disabled="true"
                  :options="paises"
                  label="name"
                  :reduce="(data) => data.id"
                  v-model="newUser.pais"
                ></v-select>

                <label>Seleccione Región:</label>
                <v-select
                  style="margin-bottom: 1rem"
                  :options="regiones"
                  label="region"
                  :reduce="(data) => data.id"
                  v-model="newUser.region"
                ></v-select>

                <label>Seleccione Comuna:</label>
                <v-select
                  style="margin-bottom: 1rem"
                  :options="comunas"
                  label="comuna"
                  :reduce="(data) => data.id"
                  v-model="newUser.comuna"
                  :disabled="!newUser.region"
                ></v-select>

                <label>Seleccione Cuadrante:</label>
                <v-select
                  style="margin-bottom: 1rem"
                  :options="cuadrantes"
                  label="nombre"
                  :reduce="(data) => data.id"
                  v-model="newUser.cuadrante_id"
                ></v-select>

                <label>Seleccione Grupo Operacional:</label>
                <v-select
                  multiple
                  style="margin-bottom: 1rem"
                  :options="grupos_operacionales"
                  label="name"
                  :reduce="(data) => data.id"
                  v-model="newUser.operational_group_ids"
                ></v-select>

                <label>Cluster de Usuario:</label>
                <v-select
                  style="margin-bottom: 1rem"
                  multiple
                  :options="clusters"
                  label="name"
                  :reduce="(data) => data.id"
                  v-model="newUser.cluster"
                ></v-select>

                <label>Rol de Usuario:</label>
                <v-select
                  style="margin-bottom: 1rem"
                  :options="roles"
                  label="name"
                  :reduce="(data) => data.id"
                  v-model="newUser.role_id"
                ></v-select>
              </CCol>
            </CRow>

            <div style="display: flex; align-items: center">
              <!--                     <CButton @click="createUser" class="mt-2" color="success"><CSpinner color="white" size="sm" v-if="loader"/> Guardar</CButton> -->
            </div>
          </CForm>
        </CCardBody>
      </CCard>
      <template #footer>
        <div style="display: flex; justify-content: space-between; width: 100%">
          <div >
            <ul v-if="errors.length">
              <li v-for="error in errors" :key="error">* {{ error }}</li>
            </ul>
          </div>

          <div>
            <CButton color="primary"  class="m-1" @click="closeModal">
              Cancelar
            </CButton>
            <CButton
              :disabled="!newUser.name || !newUser.password || !newUser.email || !newUser.direccion || !newUser.telefono || !newUser.cuadrante_id || !newUser.role_id"
              @click="createUser"
              class="m-1"
              color="success"
              ><CSpinner size="sm" color="white"  v-if="loader" />
              Guardar</CButton
            >
          </div>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validate, clean, format } from "rut.js";
import moment from "moment";
import {
  getCuadrantes,
  getPaisesActivos,
  getRegionesActivas,
  getComunasActivas,
  getOperationalGroups
} from "../../api/cuadrantes";
import { createUser, getRoles } from "../../api/user";

export default {
  name: "CreateUser",
  props: ["showModal", "closeModal", "getUsers"],
  components: { "v-select": vSelect },
  data: () => {
    return {
      toasts: [],
      newUser: {
        name: "",
        rut: "",
        nacimiento: "",
        email: "",
        telefono: "",
        region: "",
        comuna: "",
        direccion: "",
        pais: null,
        region: "",
        comuna: "",
        nacimiento: "",
        genero: "",
        cuadrante_id: "",
        cluster: [],
        role_id: '',
        operational_group_ids: [],
      },
      clusters: [
        { id: 1, name: "Mujeres" },
        { id: 2, name: "Comercio" },
        { id: 3, name: "Hogar" },
      ],
      generos: [
        { id: 1, name: "Masculino" },
        { id: 2, name: "Femenino" },
      ],
      cuadrantes: [],
      grupos_operacionales: [],
      roles: [],
      paises: [],
      regiones: [],
      comunas: [],
      validRut: null,
      errors: [],
      loader: false,
    };
  },
  watch: {
    "newUser.pais"(pais) {
      this.getRegionesActivas(pais);
    },
    "newUser.region"(region) {
      this.getComunasActivas(region);
    },
  },
  methods: {
    countryName(id) {
      const country = this.paises.find((country) => country.id === id);
      return country.name;
    },
    regionName(id) {
      const region = this.regiones.find((region) => region.id === id);
      return region.region;
    },
    validateEmail() {
      this.errors = [];
      if (!this.validEmail(this.newUser.email)) {
        this.errors.push("Dirección de correo electrónico inválida.");
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    getCuadrantes() {
      const data = {
        page: 1,
        limit: 9999999,
      };

      getCuadrantes(data)
        .then((res) => {
          this.cuadrantes = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getPaisesActivos() {
      getPaisesActivos().then((res) => {
        this.paises = res.paises;
      });
    },
    getRegionesActivas(pais_id) {
      getRegionesActivas({ pais_id }).then((res) => {
        this.regiones = res.regiones;
      });
    },
    getComunasActivas(region_id) {
      getComunasActivas({ region_id }).then((res) => {
        this.comunas = res.comunas;
        this.newUser.comuna = "";
      });
    },
    getRoles() {
      getRoles().then((res) => {
        if (this.$store.state.userData?.role?.user_role_id == 3) {
          this.roles = res.roles.filter(role => !(role.id === 4 || role.id === 5));
        } else if (this.$store.state.userData?.role?.user_role_id == 2) 
          this.roles = res.roles.filter(role => !(role.id === 3 || role.id === 4 || role.id === 5));
        else {
          this.roles = res.roles;
        }
      });
    },
    getOperationalGroups() {
      getOperationalGroups().then((res) => {
        this.grupos_operacionales = res.operational_group;
      }).catch((e) => {
        console.log(e);
      })
    },
    formatRut(rut) {
      this.newUser.rut = format(rut);
      this.validRut = validate(this.newUser.rut);
    },
    createUser() {
      this.loader = true;
      const data = { ...this.newUser };
      data.nacimiento = moment(data.nacimiento).format("YYYY-MM-DD");
      data.rut = data.rut.replace(/\./g, "");
      createUser(data).then((res) => {
            console.log(2);

          this.loader = false;
          this.getUsers();
          this.newUser = {
              name: "",
                rut: "",
                nacimiento: "",
                email: "",
                telefono: "",
                region: "",
                comuna: "",
                direccion: "",
                pais: null,
                region: "",
                comuna: "",
                nacimiento: "",
                genero: "",
                cuadrante_id: "",
                cluster: [],
                role_id: '',
                operational_group_ids: []
            }
            this.closeModal();
        })
        .catch((e) => {
            console.log(1);
            this.loader = false;
            this.errors =  e.response.data.error instanceof Array ? e.response.data.error : [e.response.data.error]

            this.toasts.push({
                title: 'Error',
                message:  e.response.data.error instanceof Array ? e.response.data.error : [e.response.data.error]
            })
        });
    },
  },
  mounted() {
    this.getCuadrantes();
    this.newUser.pais = 81;
    this.getPaisesActivos();
    this.getRoles();
    this.getOperationalGroups();
  },
};
</script>
<style scoped>
</style>