<template>
  <div>
    <CModal
      title="Editar Datos de Usuario"
      size="lg"
      color="primary"
      class="edit-user-modal"
      :show.sync="showModal"
    >
      <CCard v-if="user"
        style="max-height: 70vh; overflow-y: scroll;">
        <CCardHeader>
          <b>{{user.name}}</b>
        </CCardHeader>
        <CCardBody>
                        <CForm>
                <CRow>
                <CCol>
                    <label>Nombre:</label>
                <CInput
                  placeholder="Nombre"
                  v-model='pacienteEdit.name'
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <label>Rut:</label>
                <CInput
                  placeholder="Rut"
                  v-model='pacienteEdit.rut'
                  @keyup="(rut) => {formatRut(pacienteEdit.rut)}"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                    <label>Email:</label>
                <CInput
                    placeholder="Email"
                    v-model='pacienteEdit.email'
                     @input="validateEmail"
                    >
                    <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                </CInput>
                <label>Teléfono</label>
                <CInput
                  placeholder="Teléfono"
                  v-model='pacienteEdit.telefono'
                  maxlength="9"
                >
                  <template #prepend-content><font-awesome-icon icon="phone" />  +56</template>
                </CInput>
                <label>Dirección</label>
                <CInput
                    placeholder="Dirección"
                    v-model='pacienteEdit.direccion'
                >
                    <template #prepend-content><font-awesome-icon icon="map-marked-alt" /></template>
                </CInput>

                <label>Género</label>
                <v-select :options="generos" label="name" :reduce="data => data.id" v-model="pacienteEdit.genero"></v-select>

                <label class="pt-2 mb-0">Fecha de Nacimiento: </label>
                    <datetime
                        width="100%"
                        class="pl-1 my-2 datepickers__datepicker"
                        type="date"
                        v-model="pacienteEdit.nacimiento"
                        :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
                    ></datetime>
                </CCol>

                <CCol>
                <label>Seleccione País:</label>
                <v-select style="margin-bottom: 1rem;" :disabled="true" :options="paises" label="name" :reduce="data => data.id" v-model="pacienteEdit.pais"></v-select>

                <label>Seleccione Región:</label>
                <v-select style="margin-bottom: 1rem;" :options="regiones" label="region" :reduce="data => data.id" v-model="pacienteEdit.region"></v-select>

                <label>Seleccione Comuna:</label>
                <v-select style="margin-bottom: 1rem;" :options="comunas" label="comuna" :reduce="data => data.id" v-model="pacienteEdit.comuna"></v-select>

                <label>Seleccione Cuadrante:</label>
                <v-select style="margin-bottom: 1rem;" :options="cuadrantes" label="nombre" :reduce="data => data.id" v-model="pacienteEdit.cuadrante_id"></v-select>
                
                <label>Seleccione Grupo Operacional:</label>
                <v-select multiple style="margin-bottom: 1rem" :options="grupos_operacionales" label="name" :reduce="(data) => data.id" v-model="pacienteEdit.operational_group_ids"></v-select>

                <label>Cluster de Usuario:</label>
                <v-select style="margin-bottom: 1rem;" multiple :options="clusters" label="name" :reduce="data => data.id" v-model="pacienteEdit.cluster"></v-select>
                
                <label>Rol de Usuario:</label>
                <v-select style="margin-bottom: 1rem" :options="roles" label="name" :reduce="(data) => data.id" v-model="pacienteEdit.role_id"></v-select>
                </CCol>
                </CRow>

                <CRow class="d-flex justify-content-center align-items-center mb-3" v-if="user.role.user_role_id !== 1">
            <CCol lg="12" class="c-bg-success c-py-3">
              <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                <p class="my-0">Permisos en panel</p>
              </div>
              <div class="d-flex align-items-center mb-2 py-2 px-4" style="border-bottom: 1px solid #E2E2E2;flex-wrap: wrap; justify-content: space-between;gap: 20px;">
                <div class="d-flex" style="" v-for="(permission, key) in  $store.state.userData.permisos.permissions" :key="permission.id">
                  <div class="d-flex">
                  <CSwitch
                    v-if="permission"
                    class="mr-2"
                    size="sm"
                    color="primary"
                    shape = "pill"
                    :checked.sync="pacienteEdit.permissions[key]"
                    @change="togglePermission(value, key)"
                  />
                  <p class="mb-0">{{key}}</p>
                </div>
                  </div>
              </div>
              
            </CCol>
            </CRow>
<!-- 
                <div style="display:flex; align-items:center;">
                    <CButton @click="updateUserInfoById" class="mt-2" color="success"><CSpinner color="white" size="sm" v-if="loader"/> Guardar</CButton>
                    <div v-if="errors.length">
                        <ul>
                            <li v-for="error in errors" :key="error">* {{ error }}</li>
                        </ul>
                    </div>
                </div> -->
              </CForm>
        </CCardBody>
      </CCard>
        <template #footer>
        <div style="display: flex; justify-content: space-between; width: 100%">
          <div >
            <ul v-if="errors.length">
              <li v-for="error in errors" :key="error">* {{ error }}</li>
            </ul>
          </div>

          <div>
            <CButton color="primary"  class="m-1" @click="closeModal">
              Cancelar
            </CButton>
            <CButton
              :disabled="loader"
              @click="updateUserInfoById"
              class="m-1"
              color="success"
              ><CSpinner color="white" size="sm" v-if="loader" />
              Guardar</CButton
            >
          </div>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { validate, clean, format } from 'rut.js'
import moment from 'moment'
import { getCuadrantes, getPaisesActivos, getRegionesActivas, getComunasActivas, getOperationalGroups } from '../../api/cuadrantes'
import { updateUserInfoById, getRoles } from '../../api/user'
import checkPermissions from '../../helpers/userPermissions'

export default {
    props: ['user', 'getUsers', 'closeModal', 'showModal'],
    components: {'v-select': vSelect},
    data () {
        return {
            editUser: false,
            pacienteEdit: {},
            clusters: [
                {id: 1, name: 'Mujeres'},
                {id: 2, name: 'Comercio'},
                {id: 3, name: 'Hogar'}
            ],
            generos: [
                {id: 1, name: 'Masculino'},
                {id: 2, name: 'Femenino'},
            ],
            cuadrantes: [],
            roles: [],
            grupos_operacionales: [],
            paises: [],
            regiones: [],
            comunas: [],
            validRut: null,
            errors: [],
            loader: false
        }
    },
    methods: {
        checkPermissions,
        countryName(id) {
            const country = this.paises.find(country => country.id === id);
            return country.name
        },
        regionName(id) {
            const region = this.regiones.find(region => region.id === id);
            return region.region
        },
        validateEmail() {
            this.errors = [];
            if (!this.validEmail(this.pacienteEdit.email)) {
                this.errors.push('Dirección de correo electrónico inválida.');
            }
        },
        validEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        getCuadrantes(){
            const data = {
                page: 1,
                limit: 9999999
            }

            getCuadrantes(data).then(res => {
                this.cuadrantes = res.data
            }).catch(e => {
                console.log(e);
            })
        },
        getRoles() {
          getRoles().then((res) => {
            if (this.$store.state.userData?.role?.user_role_id == 3) {
              this.roles = res.roles.filter(role => !(role.id === 4 || role.id === 5));
            } else if (this.$store.state.userData?.role?.user_role_id == 2) 
              this.roles = res.roles.filter(role => !(role.id === 3 || role.id === 4 || role.id === 5));
            else {
              this.roles = res.roles;
            }
          });
        },
        getOperationalGroups() {
          getOperationalGroups().then((res) => {
            this.grupos_operacionales = res.operational_group;
          }).catch((e) => {
            console.log(e);
          })
        },
        getPaisesActivos() {
            getPaisesActivos().then(res => {
                this.paises = res.paises
            })
        },
        getRegionesActivas(pais_id) {
            getRegionesActivas({pais_id}).then(res => {
                this.regiones = res.regiones
            })
        },
        getComunasActivas(region_id) {
            getComunasActivas({region_id}).then(res => {
                this.comunas = res.comunas
                if (region_id !== this.user?.info?.region) {
                    this.pacienteEdit.comuna = ''
                } else {
                    this.pacienteEdit.comuna = this.user?.info?.comuna
                }
            })
        },
        formatRut(rut) {
            this.pacienteEdit.rut = format(rut)  
            this.validRut = validate(this.pacienteEdit.rut)
        },
        updateUserInfoById() {
            this.loader = true
            const data = {...this.pacienteEdit}
            data.nacimiento = moment(data.nacimiento).format('YYYY-MM-DD')
            data.rut = data.rut.replace(/\./g, '');
            data.permissions = Object.entries(data.permissions).filter(([key, value]) => value === true).reduce((acc, [key, value]) => {
              acc[key] = value;
              return acc;
            }, {});

            updateUserInfoById(data).then(res => {
                this.loader = false
                this.getUsers()
                this.editUser = false;
                this.closeModal()
            }).catch(e => {
                this.loader = false
                console.log(e);
            })
        },
        togglePermission(value, key) {
          this.pacienteEdit[key] = !this.pacienteEdit[key]
        }
    },
    filters: {
        filterDate: function(date){
            return moment(date).format("DD-MM-YYYY")
        },
        age: function(date){
            const birthdate = moment(date);
            return moment().diff(birthdate, 'years');
        },
    },
    watch: {
    'pacienteEdit.pais'(pais){
        this.getRegionesActivas(pais)
    },
    'pacienteEdit.region'(region){
        this.getComunasActivas(region)
    },
    user(user) {
        this.pacienteEdit = {
            id: user?.id ? user?.id : '',
            name: user?.name ? user?.name : '',
            rut: user?.info?.rut ? format(user?.info?.rut) : '',
            nacimiento: user?.info?.nacimiento ? user?.info?.nacimiento : '',
            email: user?.email ? user?.email : '',
            telefono: user?.info?.telefono ? user?.info?.telefono: '',
            region: user?.info?.region ? user?.info?.region : '',
            comuna: user?.info?.comuna_data?.comuna ? user?.info?.comuna_data?.comuna : '',
            direccion: user?.info?.direccion ? user?.info?.direccion : '',
            pais: 81, /* user?.info?.pais ? user?.info?.pais : '', */
            region: user?.info?.region ? user?.info?.region : '',
            comuna: user?.info?.comuna ? user?.info?.comuna : '',
            nacimiento: user?.info?.nacimiento ? user?.info?.nacimiento : '',
            genero: user?.info?.genero ? user?.info?.genero : '',
            cuadrante_id: user?.info?.cuadrante?.id ? user?.info?.cuadrante?.id : '',
            cluster: user?.info?.cluster ? user?.info?.cluster : [],
            role_id: user?.role?.user_role_id ? user?.role?.user_role_id : '',
            operational_group_ids: user?.operational_groups ? user?.operational_groups.map(og => og.operational_group_id) : [],
            permissions: user?.permisos?.permissions
        }  
    }
    },
    mounted () {
        this.getCuadrantes();
        this.getPaisesActivos(); 
        this.getRoles();
        this.getOperationalGroups();
    },
}
</script>